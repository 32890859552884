<template>
  <v-radio-group v-model="myValue" :dense="dense" :rules="rules" style="margin-top:0">
    <template v-slot:label v-if="htmlLabel">
      <div v-html="htmlLabel"></div>
    </template>
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData"></slot>
    </template>
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName"></slot>
    </template>
    <template v-for="(item, index) in items">
      <v-radio :key="index" v-bind="item" :label="item.text"></v-radio>
      <p v-if="item.subtext" :key="'text-' + index" style="margin:.5em 1em 1em 2em" v-html="item.subtext"></p>
    </template>
  </v-radio-group>
</template>
<style>
  div.v-input.v-input--radio-group.v-input--radio-group--column legend {
    display: block;
    min-height: 30px;
  }
</style>
<script>
import { computed, ref, watch } from '@vue/composition-api'
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    label: String,
    dense: Boolean,
    required: Boolean
  },
  setup (props, { root, emit }) {
    const asteriskOnRequired = computed(() => root.$store.state.forms.formSettings.asteriskOnRequired)
    const htmlLabel = computed(() => {
      if (props.required && (asteriskOnRequired.value === 'true' || asteriskOnRequired.value === true)) {
        return props.label + '<span class="error--text fas fa-asterisk" style="font-size:.6em;transform:translateY(-.6em);margin-left:.2em"></span>'
      }
      return props.label
    })
    const myValue = ref('')

    watch(() => props.value, (val) => {
      myValue.value = val
    })

    watch(myValue, (val) => {
      emit('input', val)
    })

    const rules = computed(() => {
      let arr = []
      if (props.required) {
        arr.push(v => !!v || 'Required')
      }
      return arr
    })

    return {
      htmlLabel,
      myValue,
      rules
    }
  }
}
</script>
